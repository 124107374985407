<template>
  <div class="order">
    <van-nav-bar :title="$t('newTabbar.shopPage.game.title')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
    </van-nav-bar>
    <List url="/find/lottery/order" listWrapClass="order_content">
      <template #item="{ data }">
        <div class="order_list">
          <div class="list">
            <div class="list_label">{{ $t('newTabbar.shopPage.game.order')}}</div>
            <div class="list_value">{{ data.expect }}</div>
          </div>
          <div class="list">
            <div class="list_label">{{ $t('newTabbar.shopPage.game.userphone') }}</div>
            <div class="list_value">{{ formatNumber(data.userphone) }}</div>
          </div>
          <div class="list">
            <div class="list_label">{{ $t('newTabbar.shopPage.game.amount') }}</div>
            <div class="list_value">{{ $money(data.total_money) }}</div>
          </div>
          <div class="list">
            <div class="list_label">{{ $t('newTabbar.shopPage.game.bet') }}</div>
            <div class="list_right">
              <div class="list_right_img" v-for="(value, indexs) in data.code_info" :key="indexs">
              <template v-if="value.logo">
                <van-image width="24" height="24" :src="$img(value.logo && value.logo[0])" />
              </template>
            </div>
            </div>
          </div>
          <div class="list">
            <div class="list_label">{{ $t('newTabbar.shopPage.game.result') }}</div>
            <div class="list_right">
              <div class="list_right_img" v-for="(value, indexs) in data.open_info" :key="indexs">
                <template v-if="value.logo">
                  <van-image width="24" height="24" :src="$img(value.logo && value.logo[0])" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </List>
  </div>
</template>
<script>
import List from '@/components/List.vue';
import { NavBar, icon, Image as VanImage } from 'vant';
export default {
  components: {
    List,
    [NavBar.name]: NavBar,
    [icon.name]: icon,
    [VanImage.name]: VanImage
  },
  data() {
    return {

    }
  },
  methods: {
    formatNumber(str) {
      if (typeof str !== 'string') {
        return str;
      }
      const length = str.length;
      if (length <= 6) {
        return str;
      }
      const firstThreeChars = str.slice(0, 3);
      const lastThreeChars = str.slice(length - 3, length);
      const asterisks = '*'.repeat(length - 6);
      return firstThreeChars + asterisks + lastThreeChars;
    },
  }
}
</script>
<style lang="less" scoped>
@themeColor: var(--navBg);
@white: #fff;

.widthCalc(@name, @width) {
  @{name}: calc(~"@{width}" / 16)
}

.flex(@jus: normal, @ali: normal) {
  display: flex;
  justify-content: @jus;
  align-items: @ali;
}

.backgroundColor(@colors) {
  background-color: @colors;
}

.glossy {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, .12) 51%, rgba(0, 0, 0, .04)) !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.order {
  min-height: 100vh;
  .backgroundColor(#f3f3f3);

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      background-color: @themeColor;
      .widthCalc(height, 50rem);
    }

    /deep/ .van-nav-bar__title {
      color: @white;
    }
  }
  /deep/ .order_content{
    .backgroundColor(@white);
    .widthCalc(margin, 8rem);
    .widthCalc(padding, 8rem);
    .widthCalc(border-radius, 8rem)
  }
  .order_list{
    .list{
      .flex(normal,center);
      .widthCalc(margin-bottom,4rem);
      &_label{
        .widthCalc(font-size, 16rem);
        // .widthCalc(margin-right, 8rem);
        .widthCalc(line-height, 20rem);
        .widthCalc(width, 88rem);
      }
      &_value{
        .flex(normal,center);
        .widthCalc(font-size, 16rem);
      }
      &_right{
        width: calc(100% - calc(88rem / 16));
        // .flex();
        display: flex;
        flex-wrap: wrap;
        &_img{
          .widthCalc(margin, 4rem);
          margin-bottom: 0;
          .flex(center,center);
        }
      }
    }
    .list:last-child{
      margin-bottom: 0;
    }
  }
}
</style>